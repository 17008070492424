<template>
  <div class="box flex_row">
    <div class="line"></div>
    <div class="fs_24 fw_bold m_lr_10">{{ title }}</div>
    <div class="more fs_14"><router-link :to="{path:'/resource/search',query:{sortDiv:(title=='校本热门资源'?'1':'3'),keyword:'',scene:'school'}}">更多</router-link><i class="el-icon-arrow-right"></i></div>
  </div>
</template>
<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {}
  },
  methods: {}
}
</script>
<style scoped>
.box {
  height          : 5rem;
  line-height     : 5rem;
  width           : 100%;
  justify-content : left;
  position        : relative;
}

.line {
  width            : .5rem;
  height           : 3rem;
  background-color : #1f62ec;
  margin-top       : 1rem;
  border-radius    : .2rem;
}

.more {
  position    : absolute;
  right       : 0;
  line-height : 6rem;
}
</style>
