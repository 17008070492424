// 引入封装的第一层axios接口
import request from "utils/req";

// 首页资源
export const getResource = (params) => {
    return request({
        url: '/api/schoolIndex/getSchoolIndexPage',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 收藏资源
export const collectResource = (params) => {
    return request({
        url: '/api/schoolIndex/addResourcesCollect',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 点赞资源
export const likeResource = (params) => {
    return request({
        url: '/api/schoolIndex/addResourcesLike',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 学校信息
export const deptInfo = (params) => {
    return request({
        url: '/api/blade-system/dept/detailById',
        method: 'get',
        params: {
            ...params
        }
    })
}

// 学校信息
export const browsingRecords = (params) => {
    return request({
        url: '/api/intelligentData/putTeacherLog',
        method: 'get',
        params: {
            ...params
        }
    })
}


