<template>
  <div class="box flex_row">
    <div class="line"></div>
    <div class="fs_24 fw_bold m_lr_10">{{ title }}</div>
    <div class="line line_right"></div>
    <div class="more fs_14"><router-link :to="{path:'/resource/region/search',query:{sortDiv:(title=='热门资源'?'1':'3'),id:'1',keyword:''}}">更多</router-link> <i class="el-icon-arrow-right"></i></div>
  </div>
</template>
<script>
export default {
  name: 'Title',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data: () => {
    return {}
  },
  methods: {}
}
</script>
<style scoped>
.box {
  height          : 5rem;
  line-height     : 5rem;
  width           : 100%;
  justify-content : center;
  position        : relative;
}

.line {
  width         : 15rem;
  height        : .5rem;
  background    : linear-gradient(to right, #ffffff, #1e62ec);
  margin-top    : 2.4rem;
  border-radius : .2rem;
}

.line_right {
  background : linear-gradient(to left, #ffffff, #1e62ec);
}

.more {
  position    : absolute;
  right       : 0;
  line-height : 6rem;
}
</style>
