<template>
  <div class="resource_list_box">
    <regionTitle v-if="title.type==='region'" :title="title.content"/>
    <schoolTitle v-else :title="title.content"/>
    <div v-loading="loading">
      <div class="flex_row_wrap" v-if="resourceList.length!==0">
        <Resource v-for="data of resourceList" :key="data.id" :resourceInfo="data" :scene="title.type"/>
      </div>
      <el-empty description="暂无内容" v-else></el-empty>
    </div>
  </div>
</template>
<script>
import Resource from "@/views/resourceCenter/components/region/RegionRescource";
import regionTitle from "@/views/resourceCenter/components/region/Title";
import schoolTitle from "@/views/resourceCenter/components/school/Title";

export default {
  name: 'ResourceList',
  components: {
    regionTitle,
    Resource,
    schoolTitle
  },
  props: {
    resourceList: {
      type: Array,
      default() {
        return []
      }
    },
    title: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => {
    return {
      loading: true
    }
  },
  watch: {
    resourceList() {
      this.loading = false
    }
  },
}
</script>
<style scoped>
.resource_list_box {
  padding-bottom : 2rem;
}
</style>
