<template>
  <div>
    <div>
      <img :src="bannerImg" alt="" style="width: 100%;">
    </div>
    <div style="width: 120rem;margin: auto;">
      <ResourceList :title="{type: 'school',content:'校本热门资源'}" :resourceList="hotResourceList"/>
      <ResourceList :title="{type: 'school',content:'校本推荐资源'}" :resourceList="boutiqueResourceList"/>
    </div>
  </div>
</template>
<script>
import {getResource} from '@/api/resourceCenter/school'
import ResourceList from '@/views/resourceCenter/components/region/ResourceList'
import {sessionGet} from "utils/local";
import bannerImg from '@/assets/image/resource_school.png'

export default {
  name: 'index',
  components: {
    ResourceList
  },
  data: () => {
    return {
      hotResourceList: [],
      boutiqueResourceList: [],
      catalogueList: [],
      bannerImg
    }
  },
  computed: {
    userInfo() {
      let user = sessionGet('userInfo')
      if (user) {
        return user
      } else {
        return {}
      }
    }
  },
  mounted() {
    this.getHotResourceList()
    this.getBoutiqueResourceList()
  },
  methods: {
    // 获取首页热门推荐
    getHotResourceList() {
      getResource({current: 1, size: 8, sortDiv: 1, deptId: this.userInfo.dept_id}).then(res => {
        // console.log('热门资源 ', res)
        this.hotResourceList = this.processingData(res.data.records)
      })
    },
    // 获取首页精品推荐
    getBoutiqueResourceList() {
      getResource({current: 1, size: 8, sortDiv: 3, deptId: this.userInfo.dept_id}).then(res => {
        // console.log('精品推荐', res)
        this.boutiqueResourceList = this.processingData(res.data.records)
      })
    },
    // 处理获取的数据
    processingData(resourceList) {
      resourceList.forEach(data => {
        data.teacherInfo = data.introduce.split('</br>')
      })
      return resourceList
    }
  }
}
</script>
<style scoped>
</style>
